
.twr-bg-card {
    background: #05042A;
    border-radius: 21px;
    padding: 66px 0px;
    box-shadow: 0 8px 10px rgba(0,0,0,.17);
}
.twr-hero-title {
    line-height: 120%;
    font-weight: 700;
    text-align: center;
    font-size: 56px;
    letter-spacing: -0.02em;
    padding-inline: 40px;
    color: #fff;
    text-transform: uppercase;
}
.twr-hero-title span{
    color: #FF003C;
}

#home-view {
    padding: 50px 0;
}
.twr-services .card-title {
    text-align: center;
}
.twr-services {
    padding: 50px 0;
}
.twr-services h2 {
    padding-bottom: 65px;
    padding-top: 30px;
}
.hero-image {
    width: 300px;
    margin: 0 auto;
    display: table;
}
.twr-hero-blocks {
    padding: 58px 0;
}
.hero-list ul {
    line-height: 1.5em;
    margin: 5px 0 15px;
    padding: 0;
  }
  
  .hero-list ul li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
    color: #fff;
    line-height: 37px;
    text-transform: uppercase;
    font-size: 19px;
    letter-spacing: 0.9px;
    font-family: 'Rajdhani', sans-serif;
}

.hero-list ul li::before {
    content: "";
    position: absolute;
    left: -8px;
    top: 8px;
    width: 15px;
    height: 15px;
    background-color: #FF003C;
}
.services-image img {
    padding: 10px;
    border-radius: 12px;
    width: 70px;
}
.services-image img.color-1 {
    background-color: #F3E9D9;
}
.services-image img.color-2 {
    background-color: #CFE9E7;
}
.services-image img.color-3 {
    background-color: #F5F0D4;
}
.services-image img.color-4 {
    background-color: #E6DFF2;
}
.services-image img.color-5 {
    background-color: #F1DFDE;
}
.services-image img.color-6 {
    background-color: #D2E9F2;
}

.services-image {
    padding: 5px;
}
.twr-services .card{
    border-radius: 12px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 17%);
}
.twr-about img {
    width: 100%;
}
.twr-about {
    background-color: #F2F7F8;
    margin: 45px 0;
    box-shadow: 0 8px 10px rgba(0,0,0,.17);
}
.twr-about h2 {
    text-align: center;
    margin-top: 9px;
    margin-bottom: 20px;
    font-size: 38px;
    font-weight: 700;
    text-transform: uppercase;
}
.twr-about-content {
    padding: 46px;
    text-align: center;
}
.twr-about p {
    font-size: 19px;
    text-align: justify;
}
.twr-about button {
    margin-top: 20px;
}
.twr-btn{
    color: #fff;
    background: #FF003C;
    border: 1px solid #FF003C;
    padding: 12px 30px;
    border-radius: 5px;
    line-height: 20px;
}

.twr-btn:hover{
    background: #fff;
    border: 1px solid #FF003C;
    color: #FF003C;
    text-shadow: 0 0 0.01px #FF003C;
}
.twr-services a {
    text-decoration: none;
    color: #000;
}


@media (min-width: 1600px){
#home-view h1 {
    padding-inline: 90px;
    font-size: 72px;
    
}
.services-first-row{
    padding-bottom: 20px;
}
}

@media (max-width: 767px){
#home-view h1 {
    padding-inline: 20px;
    font-size: 40px;
    
}
#home-view .container{
    padding: 0;
}
.twr-bg-card {
    border-radius: 0px;
}
.hero-list {
    padding: 0 14px;
    margin-top: 46px;
}
.hero-list ul li {
    font-size: 14px;
    letter-spacing: 0;
}

}