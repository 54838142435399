body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5{
  font-family: 'Rajdhani', sans-serif;
}
p {
    font-family: 'Barlow Condensed', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-logo{
  width: 300px;
}

.twr-nav-link {
  font-family: 'Rajdhani';
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  color: #05042A !important;
}


@media (max-width: 767px){
  .header-logo{
    width: 180px;
  }
}

@media (min-width: 1600px){
  .container{
    width: 1100px;
  }
  .navbar {
    padding-left: 20px;
    padding-right: 20px;
}
li.nav-item {
  padding: 0 7px;
}
.twr-nav-link {
  border: 1px solid #05042A;
  border-radius: 12px;
  box-shadow: 0px 8px 10px rgb(0 0 0 / 17%);
}
.twr-nav-link:hover {
  background-color: #FF003C;
  color: #fff !important;
  border: 1px solid #FF003C;
}
.twr-nav-link.active {
  background-color: #FF003C;
  color: #fff !important;
  border: 1px solid #FF003C;
}

  }