.services-archive {
    padding: 70px 0;
    margin-top: 96px;
}
.services-archive h2 {
    color: #05042A;
    font-weight: 600;
    padding: 32px 0;
}
#services-view .who-we-are img {
    top: 261px;
}
.services-archive .card-img-top {
    padding: 100px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}
.services-archive .card-title {
    font-weight: 600;
    text-align: center;
}
.services-archive .card-text {
    text-align: justify;
}
.services-archive .card {
    text-align: center;
    border-radius: 12px;
    margin-bottom: 57px;
}



@media (min-width: 1600px){
   
}

@media (max-width: 767px){

    #services-view .who-we-are img {
        top: 407px;
    }
    
   
}