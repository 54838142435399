a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    a, a:hover {
      text-decoration: none !important; }
  
  
  .footer-20192 {
    position: relative;
    color: #fff;
    padding: 7rem 0;
    background-color: #05042A; 
    margin-top: 100px;
    padding-bottom: 45px;
  }
    .footer-20192 .container {
      position: relative; }
    .footer-20192 h3 {
      font-size: 16px;
      margin-bottom: 10px;
      margin-top: 0;
      line-height: 1.5; }
    .footer-20192 .links li {
      margin-bottom: 10px;
      line-height: 1.5;
      display: block; }
      .footer-20192 .links li a {
        color: #666873; }
        .footer-20192 .links li a:hover {
          color: #fff; }
    .footer-20192 .social li {
      display: inline-block;
      position: relative; }
      .footer-20192 .social li a {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
        margin: 0;
        padding: 0;
        background-color: #FF003C;
        color: #fff; }
        .footer-20192 .social li a > span {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
    .footer-20192 .footer-logo {
      color: #fff;
      font-size: 20px;
      text-transform: uppercase;
      letter-spacing: .1rem; }
      .footer-20192 .copyright {
        color: #fff;
        font-size: 20px;
        letter-spacing: 0.9px;
        text-align: center;
        padding-top: 30px;
        border-top: 1px solid #CFE9E7;
        margin-top: 32px;
    }
    .footer-20192 .cta {
      -webkit-box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
      box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
      padding: 20px;
      background-color: #FF003C;
      top: -150px;
      position: relative; 
      border-radius: 12px;
    }
      .footer-20192 .cta h2, .footer-20192 .cta h3 {
        line-height: 1.5; }
      .footer-20192 .cta h3 {
        font-size: 20px; }

        .twr-footer-contact li {
          margin-bottom: 16px;
      }
      .footer-20192 h3 {
        font-size: 23px;
        border-bottom: 1px solid;
        width: fit-content;
        margin-bottom: 20px;
        text-transform: uppercase;
    }