.who-we-are {
    background: #05042A;
    padding: 97px 0;
    padding-bottom: 314px;
    
}
.twr-who-we-are-content{
    position: relative;
    
}
.who-we-are img {
    width: 100%;
    border-radius: 14px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 17%);
    position: absolute;
    left: 0;
    top: 304px;
}

.twr-who-we-are-content h1 {
    text-align: center;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 21px;
}
.twr-who-we-are-content h1 span{
    color: #FF003C;
}
.twr-who-we-are-content p {
    font-size: 19px;
    text-align: justify;
    color: #fff;
    padding-bottom: 32px;
}
.why-choose-us {
    padding: 70px 0;
    margin-top: 96px;
}
.why-choose-us h2 {
    color: #05042A;
    font-weight: 600;
    padding: 32px 0;
}
.why-choose-us ul li {
    font-family: 'Rajdhani', sans-serif;
    color: #000;
    font-size: 18px;
    text-align: justify;
    padding-bottom: 20px;
    line-height: 21px;
}
.why-choose-us ul li span {
    color: #FF003C;
    font-weight: 600;
}
.why-choose-img {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 17%);
}
.vision-mission .container {
    background: #CFE9E7;
    border-radius: 12px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 17%);
}
.vision-mission {
    padding: 52px 0;
}
.vision-mission h2 {
    text-align: center;
    border: 1px solid #05042A;
    border-radius: 12px;
    width: fit-content;
    padding: 7px 31px;
    margin: 0 auto;
    margin-bottom: 37px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 17%);
    color: #FF003C;
}
.vision-mission h2:hover {
    background: #05042A;
    color: #fff;
}

@media (min-width: 1600px){
    .twr-who-we-are-content h1 {
        font-size: 50px;
        line-height: 63px;
    }
    .vision-mission .row .col-md-6 {
        padding: 53px 69px;
        text-align: justify;
    }
}

@media (max-width: 767px){

    .who-we-are {
        padding-bottom: 92px;
    }

    .who-we-are img {
        top: 450px;
    }
    .why-choose-img {
        margin-bottom: 48px;
    }
    .vision-mission .row .col-md-6 {
        padding: 44px 31px;
        text-align: justify;
    }
   
}