section.contact-area {
    padding: 152px 0;
    margin-bottom: 124px;
    padding-top: 264px;
}
#contact-view .who-we-are img {
    top: 169px;
    box-shadow: none;
}
.contact-form input {
    outline: none;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    padding: 10px 0;
}
.contact-form textarea {
    outline: none;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    padding: 10px 0;
}
[placeholder]:focus::-webkit-input-placeholder {
    transition: text-indent 0.4s 0.4s ease; 
    text-indent: -100%;
    opacity: 1;
 }


.contact-area .container {
    background: #05042A;
    padding: 55px;
    border-radius: 13px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 17%);
}
.contact-title {
    font-weight: 700;
    color: #fff;
    line-height: 67px;
}
.contact-title span{
    color: #FF003C;
}
.contact-info {
    padding: 42px 0;
}
.contact-info li {
    list-style: none;
    margin-bottom: 11px;
    padding: 10px 10px;
    width: fit-content;
    font-size: 23px;
    color: #fff;
}
.contact-info li i {
    margin-right: 19px;
    color: #FF003C;
}
.contact-form {
    padding: 30px;
    background: #fff;
    border-radius: 12px;
}
.contact-form .form-group {
    margin-bottom: 24px;
}
.twr-btn i {
    margin-right: 12px;
}
.form-control:focus {
    border-color: #000 !important;
    box-shadow: none !important;
}
@media (min-width: 1600px){
    .contact-title {
        font-size: 50px;
    }
}

@media (max-width: 767px){
    .contact-title {
        font-size: 30px;
    }
}